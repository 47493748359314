import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloadingStrategyService } from '../providers/utils/preload.service';

import { StateConditionsPage } from '../pages/information/state-conditions/state-conditions.component';

const routes: Routes = [
  { path: 'introduction', loadChildren: () => import('../pages/introduction/introduction.module').then(m => m.IntroductionPageModule)},
  { path: 'welcome', loadChildren: () => import('../pages/welcome/welcome.module').then(m => m.WelcomePageModule)},
  { path: 'scanner-start', loadChildren: () => import('../scanner/scanner-start/scanner-start.module').then(m => m.ScannerStartPageModule) },
  { path: 'my-account', loadChildren: () => import('../pages/my-account/my-account.module').then(m => m.MyAccountPageModule) },
  { path: 'my-information', loadChildren: () => import('../pages/my-account/my-information/my-information.module').then(m => m.MyInformationPageModule) },
  { path: 'my-sales', loadChildren: () => import('../pages/my-account/my-sales/my-sales.module').then(m => m.MySalesPageModule) },
  { path: 'my-sales-detail', loadChildren: () => import('../pages/my-account/my-sales/my-sales-detail/my-sales-detail.module').then(m => m.MySalesDetailPageModule) },
  { path: 'my-sales-detail-edit-list', loadChildren: () => import('../pages/my-account/my-sales/my-sales-detail/my-sales-detail-edit-list/my-sales-detail-edit-list.module').then(m => m.MySalesDetailEditListPageModule) },
  { path: 'signup', data: { preload: true }, loadChildren: () => import('../pages/signup/signup.module').then(m => m.SignupPageModule) },
  { path: 'signup-confirmation', data: { preload: true }, loadChildren: () => import('../pages/signup-confirmation/signup-confirmation.module').then(m => m.SignupConfirmationPageModule) },
  { path: 'login', data: { preload: true }, loadChildren: () => import('../pages/login/login-page.module').then(m => m.LoginPageModule)},
  { path: 'password-reset', data: { preload: true }, loadChildren: () => import('../pages/password-reset/password-reset.module').then(m => m.PasswordResetPageModule) },
  { path: 'password-reset-email-sent', loadChildren: () => import('../pages/password-reset-email-sent/password-reset-email-sent.module').then(m => m.PasswordResetEmailSentPageModule) },
  { path: 'password-reset-confirm', loadChildren: () => import('../pages/password-reset-confirm/password-reset-confirm.module').then(m => m.PasswordResetConfirmPageModule) },
  { path: 'password-edit', loadChildren: () => import('../pages/password-edit/password-edit.module').then(m => m.PasswordEditPageModule) },
  { path: 'checklist', loadChildren: () => import('../pages/checklist/checklist.module').then(m => m.ChecklistPageModule) },
  { path: 'checkout-list', loadChildren: () => import('../checkout/checkout-list/checkout-list.module').then(m => m.CheckoutListPageModule) },
  { path: 'checkout-pack', loadChildren: () => import('../checkout/checkout-pack/checkout-pack.module').then(m => m.CheckoutPackPageModule) },
  { path: 'checkout-information', loadChildren: () => import('../checkout/checkout-information/checkout-information.module').then(m => m.CheckoutInformationPageModule) },
  { path: 'checkout-donate', loadChildren: () => import('../checkout/checkout-donate/checkout-donate.module').then(m => m.CheckoutDonatePageModule) },
  { path: 'checkout-control', loadChildren: () => import('../checkout/checkout-control/checkout-control.module').then(m => m.CheckoutControlPageModule) },
  { path: 'checkout-confirmed', loadChildren: () => import('../checkout/checkout-confirmed/checkout-confirmed.module').then(m => m.CheckoutConfirmedPageModule) },
  { path: 'dev-config', loadChildren: () => import('../pages/dev-config/dev-config.module').then(m => m.DevConfigPageModule) },
  { path: 'state-conditions', component: StateConditionsPage },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadingStrategyService
    })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
