import { LoginPage } from '../../pages/login_v0/login';
import { SignupPage } from '../../pages/signup_v0/signup';
import { PasswordResetPage } from '../../pages/password-reset_v0/password-reset';

import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationProvider } from '../authentication/authentication';
import { UserAuthModel } from '../../models/user-auth';
import { Router, NavigationEnd } from '@angular/router';
import {NavService} from './nav.service';

@Injectable()
export class AuthenticationModalProvider {
  public modal: HTMLIonModalElement;
  loginModalShowing: boolean;
  isGuestUser: boolean;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    public navService: NavService,
    public authenticationProvider: AuthenticationProvider,
  ) { }

  initialize() {
    this.authenticationProvider.userAuth$.subscribe((userAuthModel: UserAuthModel) => {
      if (userAuthModel && userAuthModel.auth_token) {
        this.isGuestUser = userAuthModel.isGuestUser();
        this.handleAuthState();
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.isGuestUser) {
        this.handleAuthState();
      }
    });
  }

  handleAuthState() {
    const pagesNotRequiringAuth = ['introduction', 'welcome', 'login', 'password-reset', 'password-reset-email-sent', 'password-reset-confirm','signup','signup-confirmation'];
    const currentUrl = window.location.href;

    const isPageNotRequiringAuth = pagesNotRequiringAuth.some(path => currentUrl.includes(path));

    if (this.isGuestUser && !isPageNotRequiringAuth) {
      this.navService.navigateRoot('welcome', {
        animated: true
      });
    }
  }

  async openModal() {
    this.modal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: {
        isModal: true,
      },
    });

    this.modal.present();
  }

  async openLoginModal() {
    if (!this.loginModalShowing) {
      this.loginModalShowing = true;
      this.modal = await this.modalCtrl.create({
        component: LoginPage,
        backdropDismiss: false,
        componentProps: {
          isModal: true,
          showBackButton: false,
        },
      });

      this.modal.onDidDismiss().then((params: any) => {
        if (params.data?.signupClicked) {
          this.openSignupModal();
          this.loginModalShowing = false;
        } else if (params.data?.passwordResetClicked) {
          this.openPasswordResetModal();
          this.loginModalShowing = false;
        }
      });
      this.modal.present();
    }
  }

  async openSignupModal() {
    this.modal = await this.modalCtrl.create({
      component: SignupPage,
      backdropDismiss: false,
      componentProps: {
        isModal: true,
      },
    });

    this.modal.onDidDismiss().then((params: any) => {
      if (params.data?.loginClicked) {
        this.openLoginModal();
      } else if (params.data?.passwordResetClicked) {
        this.openPasswordResetModal();
      }
    });
    this.modal.present();
  }

  async openPasswordResetModal() {
    this.modal = await this.modalCtrl.create({
      component: PasswordResetPage,
      backdropDismiss: false,
      componentProps: {
        isModal: true,
      },
    });

    this.modal.onDidDismiss().then((params: any) => {
      if (params.data?.loginClicked) {
        this.openLoginModal();
      }
    });

    this.modal.present();
  }
}
