import { Connection } from './../providers/connection/connection.enum';
import { AppSettings } from './app.settings';
import { appVersion } from './../env/app-version';
import { ENV } from './../env/env';
import { NavService } from './../providers/utils/nav.service';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { StateConditionsModalProvider } from './../providers/utils/state-conditions.service';
import { BooklistEventsProvider } from './../providers/book/book-list-events.service';
import { AuthenticationProvider } from './../providers/authentication/authentication';
import { ProfileData } from './../interfaces/users';
import { ProfileProvider } from './../providers/profile/profile.service';
import { PlatformService } from './../providers/utils/platform.service';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ScannerServiceProvider } from '../scanner/shared/scanner.service';
import { TranslateService } from '@ngx-translate/core';
import { Platform, MenuController, IonRouterOutlet } from '@ionic/angular';
import { Keyboard } from '@capacitor/keyboard';
import { BackButtonUtil } from '../providers/utils/back-button-util';
import { LoadingProvider } from '../providers/loading/loading';
import { OfflineModalProvider } from '../providers/utils/offline-modal.service';
import { UpdateModalProvider } from '../providers/utils/update-modal.service';
import { AuthenticationModalProvider } from '../providers/utils/authentication-modal.service';
import { ConnectivityProvider } from '../providers/connection/connectivity.provider';
import { StorageService } from './../providers/utils/storage.service';
import { CheckoutListEventsProvider } from './../checkout/shared/checkout-list-events.service';
import { AnalyticsProvider } from './../providers/analytics/analytics';
import { AnalyticsEventTypes } from './../models/constants/analytics-event-types';
import { EventsService } from './../providers/utils/events-service';
import { UrlTree } from '@angular/router';
import { Instabug, BugReporting } from 'instabug-cordova';
import { ToastMessages } from '../models/constants/toast-messages';
import { ToastProvider } from '../providers/toast/toast';
import { register } from 'swiper/element/bundle';
import { AppConfig } from '../models/constants/app-config';

export interface InterfacePage {
  title: string;
  name: string;
  url: any;
  icon: string;
  logsOut?: boolean;
  index?: number;
  tabName?: string;
  tabComponent?: any;
}

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.template.html',
})
export class MyApp {
  @ViewChild('smartbannercontainer', { read: ViewContainerRef }) _vcr: any;
  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;

  rootPage: string = null;
  userRole: string = null;
  profile: ProfileData;

  Pages: InterfacePage[] = [
    // eslint-disable-next-line sonarjs/no-duplicate-string
    { title: 'Boeken opzoeken', name: 'ScannerStartPage', url: 'scanner-start', icon: 'home' },
  ];

  loggedInPages: InterfacePage[] = [
    { title: 'Mijn account', name: 'MyAccountPage', url: 'my-account', icon: 'person' },
    { title: 'Mijn gegevens', name: 'MyInformationPage', url: 'my-information', icon: 'help' },
    { title: 'Mijn verkopen', name: 'MySalesPage', url: 'my-sales', icon: 'log-out' },
  ];

  loggedOutPages: InterfacePage[] = [
    { title: 'Registreren', name: 'SignupPage', url: 'signup', icon: 'log-out', logsOut: true },
    { title: 'Inloggen', name: 'LoginPage', url: 'login', icon: 'log-in' },
  ];

  stateConditionPage: InterfacePage = { title: 'Conditievoorwaarden', name: 'StateConditionsPage', url: 'state-conditions', icon: 'help' };
  devConfigPage: InterfacePage = { title: '', name: '', url: 'dev-config', icon: 'hammer' };

  version: any;

  loader: any;

  // local ref to global for template binding
  appConfig = AppConfig;

  // eslint-disable-next-line max-params
  constructor(
    private translate: TranslateService,
    public platform: Platform,
    private platformService: PlatformService,
    public menu: MenuController,
    private profileProvider: ProfileProvider,
    private authenticationProvider: AuthenticationProvider,
    private booklistEventsProvider: BooklistEventsProvider,
    private checkoutListEventsProvider: CheckoutListEventsProvider,
    private backButton: BackButtonUtil,
    private loadingProvider: LoadingProvider,
    private offlineModal: OfflineModalProvider,
    private updateModal: UpdateModalProvider,
    private authModal: AuthenticationModalProvider,
    private connectivity: ConnectivityProvider,
    private stateConditionsModal: StateConditionsModalProvider,
    private events: EventsService,
    private analytics: AnalyticsProvider,
    private navService: NavService,
    public scannerService: ScannerServiceProvider,
    private storageService: StorageService,
    public appSettings: AppSettings,
    private deeplinks: Deeplinks,
    private readonly toastProvider: ToastProvider,
  ) {
    this.initializeApp();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async initializeApp() {
    this.platform.ready().then(async () => {

      // initialise Instabug
      try {
        Instabug.start(
          ENV.instabug.apiKey,
          [BugReporting.invocationEvents.shake],
          () => {
            console.log('Instabug initialized.');
          },
          (error) => {
            console.log('Instabug could not be initialized - ' + error);
          }
        );
      } catch (error) {
        console.error(error);
      }

      // set buildInfo for Sidebar
      this.setBuildInfo();

      this.setIntroScreen();

      this.initializeUpdateModal();

      if (this.platform.is('cordova')) {
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.setOverlaysWebView({overlay: false});
        this.backButton.fixScannerBackButton();
        if (this.platform.is('ios')) {
          Keyboard.setAccessoryBarVisible({isVisible: false});
        }
      }

      // When user directly navigate to a subpage don't load homePage.
      // Only works when in Path and Prod mode
      const isHomePage = window.location.pathname.length < 2;

      if (!this.platformService.isPwa() && isHomePage) {
        this.navService.navigateRoot('scanner-start');
      }

      // listen to authentication state
      this.authModal.initialize();

      // listen to network connection changes
      this.offlineModal.initialize();
    });

    this.listenToEvents();
    this.analytics.setListeners();

    this.initTranslate();

    await this.presentLoader();

    // start session once network is connected
    this.connectivity.readyAndConnected().then(async () => {
      await this.presentLoader();

      this.authenticationProvider.startSession();
    });

    this.connectivity.connection.subscribe((connection) => {
      if (connection !== undefined && connection !== Connection.Up) {
        this.dismissLoader();
      }
    });

    // after startSession has completed
    this.profileProvider.profileData$.subscribe((profile: ProfileData) => {
      if (profile) {
        this.profile = profile;
        this.dismissLoader();
      }
    });

    if (!this.platformService.isPwa()) {
      this.deeplinks.route({
        'scanner-start': '/scanner-start',
      }).subscribe((match) => {
        console.log('Successfully matched route', match);
        this.navService.navigateRoot('scanner-start');
      },
      (nomatch) => {
        console.error('Got a deeplink that didn\'t match', nomatch);
      });
    }
  }

  private async presentLoader() {
    if (this.loader) {
      return;
    }

    this.loader = await this.loadingProvider.createAndPresent(true, {
      backdropDismiss: false,
      duration: 30000,
    });
  }

  private dismissLoader() {
    if (!this.loader) {
      return;
    }

    this.loader.dismiss();
    this.loader = undefined;
  }

  public initializeUpdateModal() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.updateModal.initialize(this.version);
      // Set event listener for resuming app
      this.platform.resume.subscribe(() => {
        this.updateModal.initialize(this.version);
      });
    }
  }

  public async openWithInAppBrowser(url: string) {
    await Browser.open({ url });
  }

  public async openWithExternalBrowser(url: string) {
    // This is a workaround for opening links in the external browser app, e.g. for PDF's
    // Using target '_system' makes inAppBrowser open the external browser app, and in case of PWA falls back to simply opening a new tab
    const target = '_system';
    await Browser.open({ url: url, windowName: target});
  }

  async openPage(page: { url: string | any[] | UrlTree }) {
    this.navService.navigateRoot(page.url);
  }

  async openStateConditionsModal() {
    this.stateConditionsModal.openModal(true);
  }

  menuClosed() {
    this.events.publish('menu:ionDidClose');
  }

  mailTo() {
    window.open('mailto:inkoop@boekenbalie.nl', '_system');
  }

  listenToEvents() {
    this.booklistEventsProvider.init();
    this.checkoutListEventsProvider.init();
  }

  initTranslate() {
    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang('nl');
  }

  async setBuildInfo() {
    this.version = appVersion;
  }

  async setIntroScreen() {
    const introDone = await this.storageService.get('introDone');
    const isPasswordResetPage = window.location.pathname.startsWith('/password-reset-confirm');
    if (!isPasswordResetPage && !introDone && (this.platform.is('cordova') || this.platform.is('mobile')) && this.platform.width() < 769) {
      this.navService.navigateRoot('introduction');
    }
  }

  onToggle(event, type) {
    this.scannerService.menuToggled(event, type);

    if (event === true) {
      this.analytics.analyticsEventSubject.next({ eventName: AnalyticsEventTypes.ToggleFastScan, eventData: { event_category: 'scanner', event_action: type + '_toggled_on' } });
    } else if (!event) {
      this.analytics.analyticsEventSubject.next({ eventName: AnalyticsEventTypes.ToggleFastScan, eventData: { event_category: 'scanner', event_action: type + '_toggled_off' } });
    }
  }

  async doLogout() {
    await this.loadingProvider.createAndPresent(true);
    try {
      await this.navService.navigateForward('scanner-start'); // call before logout to make sure the underlying reload uses the new location
      await this.profileProvider.logout();
      this.loadingProvider.dismiss();
    } catch (e) {
      this.loadingProvider.dismiss();
      this.toastProvider.showToast(ToastMessages.genericError.text, ToastMessages.genericError.duration, ToastMessages.genericError.position);
    }
  }

}
